import React from 'react';
import { Link } from 'react-router-dom';
import backgroundImage from './images/background.png';

const MainPage = () => {
  return (
    <div className="relative min-h-screen w-full bg-white flex flex-col items-center justify-center">
      <div className="absolute inset-0 bg-contain bg-center bg-no-repeat" style={{backgroundImage: `url(${backgroundImage})`}}></div>
      <div className="z-10 space-y-4">
        <Link to="/calculator" className="block px-6 py-3 bg-green-500 text-white rounded-md text-center hover:bg-green-600 transition duration-300">
          중고가 측정
        </Link>
      </div>
      <div className="absolute bottom-4 right-4 text-gray-500 text-sm">
        Ver 1.0
      </div>
    </div>
  );
};

export default MainPage;
