import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="bg-white shadow-md">
      <div className="container mx-auto px-6 py-3 flex justify-between items-center">
        <Link to="/" className="text-xl font-bold text-blue-600 hover:text-blue-800">
          G.C.컴
        </Link>
        <nav className="flex items-center">
          <Link to="/calculator" className="mx-2 text-gray-600 hover:text-blue-600">중고가 측정</Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
