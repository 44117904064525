import React, { useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

const parts = {
  cpu: [
    { name: 'Intel Core i3-8100', price: 30, performanceScore: 76 },
    { name: 'Intel Core i3-9100f', price: 30, performanceScore: 86 },
    { name: 'Intel Core i3-9100', price: 35, performanceScore: 86 },
    { name: 'Intel Core i3-10100f', price: 45, performanceScore: 86 },
    { name: 'Intel Core i3-10100', price: 50, performanceScore: 86 },
    { name: 'Intel Core i3-10105f', price: 45, performanceScore: 88 },
    { name: 'Intel Core i3-10105', price: 55, performanceScore: 88 },
    { name: 'Intel Core i3-12100f', price: 70, performanceScore: 101 },
    { name: 'Intel Core i3-12100', price: 90, performanceScore: 101 },
    { name: 'Intel Core i3-12300', price: 100, performanceScore: 103 },
    { name: 'Intel Core i3-13100f', price: 95, performanceScore: 103 },
    { name: 'Intel Core i3-13100', price: 110, performanceScore: 103 },
    { name: 'Intel Core i5-8400', price: 50, performanceScore: 88 },
    { name: 'Intel Core i5-8500', price: 60, performanceScore: 88 },
    { name: 'Intel Core i5-8600', price: 65, performanceScore: 95 },
    { name: 'Intel Core i5-8600K', price: 70, performanceScore: 97 },
    { name: 'Intel Core i5-9400f', price: 60, performanceScore: 91 },
    { name: 'Intel Core i5-9400', price: 75, performanceScore: 94 },
    { name: 'Intel Core i5-9500', price: 80, performanceScore: 94 },
    { name: 'Intel Core i5-9600KF', price: 80, performanceScore: 89 },
    { name: 'Intel Core i5-9600K', price: 90, performanceScore: 100 },
    { name: 'Intel Core i5-10400f', price: 90, performanceScore: 91 },
    { name: 'Intel Core i5-10400', price: 100, performanceScore: 91 },
    { name: 'Intel Core i5-10500', price: 100, performanceScore: 90 },
    { name: 'Intel Core i5-10600', price: 100, performanceScore: 97 },
    { name: 'Intel Core i5-10600KF', price: 110, performanceScore: 99 },
    { name: 'Intel Core i5-10600K', price: 120, performanceScore: 99 },
    { name: 'Intel Core i5-11400F', price: 100, performanceScore: 101 },
    { name: 'Intel Core i5-11400', price: 110, performanceScore: 101 },
    { name: 'Intel Core i5-12400F', price: 110, performanceScore: 105 },
    { name: 'Intel Core i5-12400', price: 130, performanceScore: 105 },
    { name: 'Intel Core i5-12500', price: 150, performanceScore: 106 },
    { name: 'Intel Core i5-12600KF', price: 160, performanceScore: 117 },
    { name: 'Intel Core i5-12600K', price: 180, performanceScore: 117 },
    { name: 'Intel Core i5-13400F', price: 160, performanceScore: 114 },
    { name: 'Intel Core i5-13400', price: 200, performanceScore: 114 },
    { name: 'Intel Core i5-13500', price: 220, performanceScore: 122 },
    { name: 'Intel Core i5-13600KF', price: 250, performanceScore: 131 },
    { name: 'Intel Core i5-13600K', price: 280, performanceScore: 131 },
    { name: 'Intel Core i5-14400F', price: 220, performanceScore: 115 },
    { name: 'Intel Core i5-14400', price: 240, performanceScore: 115 },
    { name: 'Intel Core i5-14600KF', price: 280, performanceScore: 133 },
    { name: 'Intel Core i5-14600K', price: 320, performanceScore: 133 },
    { name: 'Intel Core i7-8700', price: 100, performanceScore: 94 },
    { name: 'Intel Core i7-8086K', price: 120, performanceScore: 98 },
    { name: 'Intel Core i7-8700K', price: 120, performanceScore: 95 },
    { name: 'Intel Core i7-9700F', price: 130, performanceScore: 99 },
    { name: 'Intel Core i7-9700', price: 140, performanceScore: 99 },
    { name: 'Intel Core i7-9700KF', price: 140, performanceScore: 104 },
    { name: 'Intel Core i7-9700K', price: 160, performanceScore: 104 },
    { name: 'Intel Core i7-10700F', price: 170, performanceScore: 100 },
    { name: 'Intel Core i7-10700', price: 180, performanceScore: 100 },
    { name: 'Intel Core i7-10700KF', price: 180, performanceScore: 103 },
    { name: 'Intel Core i7-10700K', price: 200, performanceScore: 104 },
    { name: 'Intel Core i7-11700F', price: 180, performanceScore: 105 },
    { name: 'Intel Core i7-11700', price: 200, performanceScore: 105 },
    { name: 'Intel Core i7-11700KF', price: 200, performanceScore: 110 },
    { name: 'Intel Core i7-11700K', price: 220, performanceScore: 109 },
    { name: 'Intel Core i7-12700F', price: 220, performanceScore: 117 },
    { name: 'Intel Core i7-12700', price: 230, performanceScore: 116 },
    { name: 'Intel Core i7-12700KF', price: 235, performanceScore: 123 },
    { name: 'Intel Core i7-12700K', price: 260, performanceScore: 123 },
    { name: 'Intel Core i7-13700F', price: 280, performanceScore: 130 },
    { name: 'Intel Core i7-13700', price: 300, performanceScore: 130 },
    { name: 'Intel Core i7-13700KF', price: 320, performanceScore: 135 },
    { name: 'Intel Core i7-13700K', price: 340, performanceScore: 135 },
    { name: 'Intel Core i7-14700F', price: 350, performanceScore: 129 },
    { name: 'Intel Core i7-14700', price: 380, performanceScore: 129 },
    { name: 'Intel Core i7-14700KF', price: 410, performanceScore: 140 },
    { name: 'Intel Core i7-14700K', price: 430, performanceScore: 140 },
    { name: 'Intel Core i9-9900', price: 240, performanceScore: 98 },
    { name: 'Intel Core i9-9900KF', price: 220, performanceScore: 105 },
    { name: 'Intel Core i9-9900K', price: 240, performanceScore: 105 },
    { name: 'Intel Core i9-9900KS', price: 250, performanceScore: 106 },
    { name: 'Intel Core i9-10850K', price: 240, performanceScore: 106 },
    { name: 'Intel Core i9-10900F', price: 220, performanceScore: 102 },
    { name: 'Intel Core i9-10900', price: 230, performanceScore: 101 },
    { name: 'Intel Core i9-10900KF', price: 230, performanceScore: 108 },
    { name: 'Intel Core i9-10900K', price: 250, performanceScore: 108 },
    { name: 'Intel Core i9-11900F', price: 220, performanceScore: 108 },
    { name: 'Intel Core i9-11900', price: 250, performanceScore: 108 },
    { name: 'Intel Core i9-11900K', price: 280, performanceScore: 111 },
    { name: 'Intel Core i9-12900F', price: 280, performanceScore: 122 },
    { name: 'Intel Core i9-12900', price: 290, performanceScore: 119 },
    { name: 'Intel Core i9-12900KF', price: 310, performanceScore: 126 },
    { name: 'Intel Core i9-12900K', price: 330, performanceScore: 126 },
    { name: 'Intel Core i9-12900KS', price: 350, performanceScore: 128 },
    { name: 'Intel Core i9-13900F', price: 390, performanceScore: 133 },
    { name: 'Intel Core i9-13900', price: 420, performanceScore: 132 },
    { name: 'Intel Core i9-13900KF', price: 440, performanceScore: 139 },
    { name: 'Intel Core i9-13900K', price: 480, performanceScore: 139 },
    { name: 'Intel Core i9-13900KS', price: 500, performanceScore: 141 },
  
    { name: 'Ryzen 3 1200', price: 10, performanceScore: 61 },
    { name: 'Ryzen 3 1300X', price: 15, performanceScore: 70 },
    { name: 'Ryzen 3 2200G', price: 22, performanceScore: 66 },
    { name: 'Ryzen 3 3200G', price: 30, performanceScore: 66 },
    { name: 'Ryzen 3 3100', price: 30, performanceScore: 84 },
    { name: 'Ryzen 3 3300X', price: 40, performanceScore: 86 },
    { name: 'Ryzen 5 1400', price: 15, performanceScore: 66 },
    { name: 'Ryzen 5 1500X', price: 15, performanceScore: 71 },
    { name: 'Ryzen 5 1600', price: 20, performanceScore: 75 },
    { name: 'Ryzen 5 1600X', price: 25, performanceScore: 80 },
    { name: 'Ryzen 5 2400G', price: 30, performanceScore: 67 },
    { name: 'Ryzen 5 2600', price: 30, performanceScore: 78 },
    { name: 'Ryzen 5 2600X', price: 35, performanceScore: 80 },
    { name: 'Ryzen 5 3400G', price: 45, performanceScore: 73 },
    { name: 'Ryzen 5 3500', price: 35, performanceScore: 87 },
    { name: 'Ryzen 5 3500X', price: 40, performanceScore: 88 },
    { name: 'Ryzen 5 3600', price: 55, performanceScore: 89 },
    { name: 'Ryzen 5 3600X', price: 60, performanceScore: 90 },
    { name: 'Ryzen 5 3600XT', price: 60, performanceScore: 91 },
    { name: 'Ryzen 5 4600G', price: 60, performanceScore: 72 },
    { name: 'Ryzen 5 4650G', price: 70, performanceScore: 73 },
    { name: 'Ryzen 5 5500', price: 75, performanceScore: 92 },
    { name: 'Ryzen 5 5500GT', price: 110, performanceScore: 95 },
    { name: 'Ryzen 5 5600G', price: 100, performanceScore: 92 },
    { name: 'Ryzen 5 5600GT', price: 120, performanceScore: 95 },
    { name: 'Ryzen 5 5600', price: 100, performanceScore: 100 },
    { name: 'Ryzen 5 5600X', price: 105, performanceScore: 103 },
    { name: 'Ryzen 5 7500F', price: 150, performanceScore: 111 },
    { name: 'Ryzen 5 7600', price: 160, performanceScore: 114 },
    { name: 'Ryzen 5 7600X', price: 180, performanceScore: 118 },
    { name: 'Ryzen 7 1700', price: 30, performanceScore: 74 },
    { name: 'Ryzen 7 1700X', price: 35, performanceScore: 76 },
    { name: 'Ryzen 7 1800X', price: 35, performanceScore: 77 },
    { name: 'Ryzen 7 2700', price: 40, performanceScore: 80 },
    { name: 'Ryzen 7 2700X', price: 45, performanceScore: 82 },
    { name: 'Ryzen 7 3800X', price: 100, performanceScore: 94 },
    { name: 'Ryzen 7 3800XT', price: 105, performanceScore: 95 },
    { name: 'Ryzen 7 4700G', price: 110, performanceScore: 87 },
    { name: 'Ryzen 7 4750G', price: 110, performanceScore: 89 },
    { name: 'Ryzen 7 5700G', price: 170, performanceScore: 95 },
    { name: 'Ryzen 7 5700X', price: 160, performanceScore: 103 },
    { name: 'Ryzen 7 5700X3D', price: 200, performanceScore: 104 },
    { name: 'Ryzen 7 5800X', price: 170, performanceScore: 105 },
    { name: 'Ryzen 7 5800X3D', price: 330, performanceScore: 112 },
    { name: 'Ryzen 7 7700', price: 210, performanceScore: 117 },
    { name: 'Ryzen 7 7700X', price: 280, performanceScore: 120 },
    { name: 'Ryzen 9 3900X', price: 170, performanceScore: 95 },
    { name: 'Ryzen 9 3900XT', price: 170, performanceScore: 96 },
    { name: 'Ryzen 9 3950X', price: 270, performanceScore: 96 },
    { name: 'Ryzen 9 5900X', price: 280, performanceScore: 106 },
    { name: 'Ryzen 9 5950X', price: 400, performanceScore: 108 },
    { name: 'Ryzen 9 7900', price: 390, performanceScore: 117 },
    { name: 'Ryzen 9 7900X', price: 410, performanceScore: 122 },
    { name: 'Ryzen 9 7950X', price: 530, performanceScore: 123 },
    { name: 'Ryzen 9 7950X3D', price: 650, performanceScore: 129 },
  ],
  gpu:[
    { name: 'GTX1050', price: 40, performanceScore: 19 },
    { name: 'GTX1050Ti', price: 55, performanceScore: 20 },
    { name: 'GTX1060', price: 60, performanceScore: 46 },
    { name: 'GTX1070', price: 90, performanceScore: 50 },
    { name: 'GTX1070Ti', price: 110, performanceScore: 77 },
    { name: 'GTX1080', price: 120, performanceScore: 84 },
    { name: 'GTX1080Ti', price: 150, performanceScore: 102 },
    { name: 'GTX1650', price: 70, performanceScore: 34 },
    { name: 'GTX1650S', price: 80, performanceScore: 48 },
    { name: 'GTX1660', price: 110, performanceScore: 56 },
    { name: 'GTX1660S', price: 120, performanceScore: 58 },
    { name: 'GTX1660Ti', price: 130, performanceScore: 62 },
    { name: 'RTX2060', price: 140, performanceScore: 73 },
    { name: 'RTX2060S', price: 160, performanceScore: 82 },
    { name: 'RTX2070', price: 180, performanceScore: 85 },
    { name: 'RTX2070S', price: 200, performanceScore: 94 },
    { name: 'RTX2080', price: 220, performanceScore: 102 },
    { name: 'RTX2080S', price: 230, performanceScore: 107 },
    { name: 'RTX2080Ti', price: 270, performanceScore: 128 },
    { name: 'RTX3050', price: 170, performanceScore: 61 },
    { name: 'RTX3060', price: 250, performanceScore: 82 },
    { name: 'RTX3060Ti', price: 270, performanceScore: 105 },
    { name: 'RTX3070', price: 310, performanceScore: 121 },
    { name: 'RTX3070Ti', price: 350, performanceScore: 130 },
    { name: 'RTX3080', price: 420, performanceScore: 153 },
    { name: 'RTX3080Ti', price: 550, performanceScore: 167 },
    { name: 'RTX3090', price: 800, performanceScore: 172 },
    { name: 'RTX3090Ti', price: 950, performanceScore: 190 },
    { name: 'RTX4060', price: 330, performanceScore: 100 },
    { name: 'RTX4060Ti', price: 440, performanceScore: 119 },
    { name: 'RTX4070', price: 600, performanceScore: 156 },
    { name: 'RTX4070S', price: 700, performanceScore: 176 },
    { name: 'RTX4070Ti', price: 800, performanceScore: 197 },
    { name: 'RTX4070TiS', price: 950, performanceScore: 205 },
    { name: 'RTX4080', price: 1100, performanceScore: 238 },
    { name: 'RTX4080S', price: 1300, performanceScore: 246 },
    { name: 'RTX4090', price: 2400, performanceScore: 303 },
  ],
  ram: [
    { name: 'DDR4 8GB', price: 13 },
    { name: 'DDR4 16GB', price: 28 },
    { name: 'DDR4 32GB', price: 65 },
    { name: 'DDR4 8GB-3200', price: 15 },
    { name: 'DDR4 16GB-3200', price: 35 },
    { name: 'DDR4 32GB-3200', price: 70 },
    { name: 'DDR4 64GB-3200', price: 160 },
  ],
  storage: [
    { name: 'M.2 128GB', price: 7 },
    { name: 'M.2 250GB', price: 10 },
    { name: 'M.2 256GB', price: 12 },
    { name: 'M.2 500GB', price: 30 },
    { name: 'M.2 1TB', price: 60 },
    { name: 'M.2 2TB', price: 140 },
  ],
  case: [
    { name: 'mini tower', price: 10 },
    { name: 'midle tower', price: 25 },
    { name: 'big tower', price: 40 },
    { name: 'premium tower', price: 100 },
  ],
  cpuCooler: [
    { name: 'single tower', price: 10 },
    { name: 'double tower', price: 20 },
    { name: 'water cooler 2fan  ', price: 50 },
    { name: 'water cooler 3fan', price: 70 },
  ],
  motherboard: [
    { name: 'H310', price: '20' },
    { name: 'B360', price: '40' },
    { name: 'Z370', price: '50' },
    { name: 'Z390', price: '60' },
    { name: 'H410', price: '30' },
    { name: 'B460', price: '50' },
    { name: 'Z490', price: '70' },
    { name: 'H510', price: '40' },
    { name: 'B560', price: '50' },
    { name: 'Z590', price: '80' },
    { name: 'H610', price: '70' },
    { name: 'B660', price: '100' },
    { name: 'Z690', price: '130' },
    { name: 'B760', price: '120' },
    { name: 'Z790', price: '150' },
    { name: 'A320', price: '20' },
    { name: 'B350', price: '30' },
    { name: 'X370', price: '50' },
    { name: 'B450', price: '50' },
    { name: 'X470', price: '70' },
    { name: 'A520', price: '50' },
    { name: 'B550', price: '80' },
    { name: 'X570', price: '120' },
    { name: 'A620', price: '70' },
    { name: 'B650', price: '90' },
    { name: 'B650E', price: '200' },
    { name: 'X670E', price: '200' },
  ],
  power: [
    { name: '500W', price: 15 },
    { name: '600W', price: 20 },
    { name: '700W', price: 25 },
    { name: '750W', price: 30 },
    { name: '800W', price: 35 },
    { name: '850W', price: 40 },
    { name: '1000W', price: 45 },
  ],
};

const PartSelector = React.memo(({ category, parts, onSelect, selectedPart, quantity, onQuantityChange }) => {
  const options = useMemo(() => 
    parts.map(part => ({ 
      value: part, 
      label: `${part.name} - ${(part.price * 1000).toLocaleString()}원` 
    })),
    [parts]
  );

  return (
    <div style={{ marginBottom: '20px' }}>
      <p style={{ marginBottom: '10px' }}>{category.toUpperCase()} 선택</p>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Select
          options={options}
          value={selectedPart ? { value: selectedPart, label: `${selectedPart.name} - ${(selectedPart.price * 1000).toLocaleString()}원` } : null}
          onChange={(selected) => onSelect(selected ? selected.value : null)}
          isClearable
          placeholder={`${category.toUpperCase()} 검색 또는 선택...`}
          style={{ flex: 1 }}
        />
        {category === 'ram' && selectedPart && (
          <input
            type="number"
            min="1"
            max="4"
            value={quantity}
            onChange={(e) => onQuantityChange(Number(e.target.value))}
            style={{ marginLeft: '10px', width: '50px', padding: '5px' }}
          />
        )}
      </div>
    </div>
  );
});

const UsedComputerPriceCalculator = () => {
  const [selectedParts, setSelectedParts] = useState({
    cpu: null,
    gpu: null,
    ram: null,
    storage: null,
    motherboard: null,
    power: null
  });
  const [ramQuantity, setRamQuantity] = useState(1);
  const navigate = useNavigate();

  const handlePartSelect = useCallback((category, part) => {
    setSelectedParts(prev => ({ ...prev, [category]: part }));
  }, []);

  const handleRamQuantityChange = useCallback((quantity) => {
    setRamQuantity(quantity);
  }, []);

  const totalPrice = useMemo(() => {
    return Object.entries(selectedParts).reduce((sum, [category, part]) => {
      if (!part) return sum;
      
      if (category === 'ram') {
        return sum + (part.price * ramQuantity * 1000);
      }
      if (typeof part.price === 'string' && part.price.includes('+@')) {
        const basePrice = parseInt(part.price) || 0;
        return sum + (basePrice * 1000);
      }
      return sum + (part.price * 1000);
    }, 0);
  }, [selectedParts, ramQuantity]);
  
  const handleSubmit = useCallback(() => {
    const cpuScore = selectedParts.cpu ? selectedParts.cpu.performanceScore : 0;
    const gpuScore = selectedParts.gpu ? selectedParts.gpu.performanceScore : 0;
    
    navigate('/results', { 
      state: { 
        selectedParts, 
        ramQuantity, 
        totalPrice, 
        cpuScore, 
        gpuScore 
      } 
    });
  }, [navigate, selectedParts, ramQuantity, totalPrice]);

  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '30px' }}>중고 컴퓨터 가격 계산기</h1>
      
      {Object.entries(parts).map(([category, categoryParts]) => (
        <PartSelector 
          key={category}
          category={category}
          parts={categoryParts}
          onSelect={(part) => handlePartSelect(category, part)}
          selectedPart={selectedParts[category]}
          quantity={category === 'ram' ? ramQuantity : undefined}
          onQuantityChange={category === 'ram' ? handleRamQuantityChange : undefined}
        />
      ))}

      <div style={{ marginBottom: '20px' }}>
        <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
          예상 중고 가격: {totalPrice.toLocaleString()}원
        </p>
      </div>

      <button
        onClick={handleSubmit}
        style={{
          backgroundColor: '#4CAF50',
          color: 'white',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          fontSize: '16px',
        }}
      >
        결과 보기
      </button>
    </div>
  );
};

export default UsedComputerPriceCalculator;