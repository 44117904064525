import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import MainPage from './MainPage';
import UsedComputerPriceCalculator from './UsedComputerPriceCalculator';
import CalculatorResults from './CalculatorResults';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/calculator" element={<UsedComputerPriceCalculator />} />
            <Route path="/results" element={<CalculatorResults />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
