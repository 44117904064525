import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const CalculatorResults = () => {
  const location = useLocation();
  const { selectedParts, ramQuantity, totalPrice, cpuScore, gpuScore } = location.state || {};

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">중고 컴퓨터 가격 계산 결과</h1>
      
      {selectedParts ? (
        <div>
          <h2 className="text-xl font-bold mb-2">선택된 부품:</h2>
          {Object.entries(selectedParts).map(([category, part]) => (
            part && <p key={category}>{category.toUpperCase()}: {part.name} - {(part.price * 1000).toLocaleString()}원</p>
          ))}
          {selectedParts.ram && <p>RAM 수량: {ramQuantity}</p>}
          <p className="text-xl font-bold mt-4">예상 중고 가격: {totalPrice.toLocaleString()}원</p>
          
          <div className="mt-6">
            <h2 className="text-xl font-bold mb-2">성능 점수:</h2>
            <p>CPU 성능 점수: {cpuScore}</p>
            <p>GPU 성능 점수: {gpuScore}</p>
          </div>

          <div className="mt-6 p-4 bg-gray-100 rounded-lg">
            <p className="text-sm">
              CPU Ryzen 5 5600(6만원)과 RTX 4060(31만원)을 100점 기준으로 하여 책정한 점수이며,
              이는 각종 게임 및 작업에서 불편함 없이 이용할 수 있는 기준점이 되는 점수입니다.
              신품가격이므로 이 점을 참고하여 중고 구매에 도움이 되셨으면 좋겠습니다. 또한 중고 가격은 각종 중고 장터에서 최근 1년동안 거래된 가격으로 책정하였습니다.
            </p>
          </div>
        </div>
      ) : (
        <p>결과를 불러올 수 없습니다.</p>
      )}

      <Link to="/calculator" className="mt-6 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        다시 계산하기
      </Link>
    </div>
  );
};

export default CalculatorResults;